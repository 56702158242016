import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaTimes,
  FaSave,
  FaTimesCircle,
  FaBarcode,
  FaMinus,
  FaPlus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';
import * as Yup from 'yup';

import api from '~/services/api';

import { Input, Select, DatePicker, InputMask } from '~/components/Form';
import { TableLoading } from '~/components/Table';

import { Container, Header, Controls } from '~/styles/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmWindow from '~/components/ConfirmWindow';
import { confirmAlert } from 'react-confirm-alert';
import formatValueOnChange from '~/util/formatValueOnChange';
import formatValue from '~/util/formatValue';
import { Content, BilletForm, Finances, Finance } from './styles';

const Edit = ({ match }) => {
  const { company, user } = useAuth();
  const formRef = useRef(null);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [billet, setBillet] = useState({});
  const [clientsOptions, setClientsOptions] = useState([]);
  const [eventsOptions, seteventsOptions] = useState([]);
  const [financialOptions, setFinancialOptions] = useState([]);

  const hasLoadedDaysToProtest = useRef(false);
  const [daysToProtestOptions, setDaysToProtestOptions] = useState([]);

  const [finance, setFinance] = useState([]);

  const loadFinancialData = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get(`/old-database/financial-data`, {
          params: {
            company_id: company.old_id,
          },
        });

        const options = response.data.map(item => ({
          value: item.id,
          label: item.title,
        }));

        setFinancialOptions(options);
      } catch {
        toast.error('Falha ao buscar dados financeiros', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  const loadClients = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            active: true,
            type: 1,
          },
        });

        const options = response.data.map(item => ({
          value: item.old_id,
          label: item.name,
        }));

        setClientsOptions(options);
      } catch {
        toast.error('Falha ao buscar clientes', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  const loadEvents = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get('/old-database/events', {
          params: {
            company: company.id,
            billet: true,
          },
        });

        if (response.data) {
          const options = response.data.map(event => ({
            value: event.id,
            label: event.name_label,
          }));
          seteventsOptions(options);
        }
      } catch (error) {
        toast.error('Erro ao buscar eventos', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  const loadBillet = useCallback(async () => {
    if (company) {
      try {
        setLoading(true);

        const response = await api.get(`/billet/${match.params.id}`, {
          params: {
            company_id: company.old_id,
          },
        });

        if (response.data) {
          const formattedBillet = {
            ...response.data,
            billet_finance: response.data.billet_finance.map(
              billet_finance => ({
                ...billet_finance,
                price: formatValue(billet_finance.price),
                temp_id: billet_finance.id || uuidv4(),
              })
            ),
          };

          setBillet(formattedBillet);
          setFinance(formattedBillet.billet_finance);
        } else {
          toast.warn('Nenhum boleto foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar boleto.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [company, match]);

  const totalFinancePrice = useMemo(() => {
    return finance.reduce((total, item) => {
      const price = parseFloat(
        item.price?.replace(/[^\d,]/g, '').replace(',', '.')
      );
      const result = total + (isNaN(price) ? 0 : price);
      return result;
    }, 0);
  }, [finance]);

  useEffect(() => {
    setBillet(prevBillet => ({
      ...prevBillet,
      price: formatValue(totalFinancePrice),
    }));
  }, [totalFinancePrice]);

  const loadDaysToProtest = useCallback(
    async (selectedFinancial, selectedProtest) => {
      if (company) {
        try {
          const response = await api.get(`/old-database/days-to-protest`, {
            params: {
              company_id: company.old_id,
              financial_data_id: selectedFinancial || billet?.financial,
              protest_id: selectedProtest || billet?.protest,
            },
          });

          const options = response.data.map(item => ({
            value: item.id,
            label: item.days_label,
          }));

          setDaysToProtestOptions(options);
        } catch {
          toast.error('Falha ao buscar dados financeiros', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [company, billet]
  );

  useEffect(() => {
    loadFinancialData();
    loadClients();
    loadEvents();
    loadBillet();
  }, [loadFinancialData, loadClients, loadEvents, loadBillet]);

  useEffect(() => {
    if (billet?.financial && !hasLoadedDaysToProtest.current) {
      if (billet?.protest !== 3) {
        loadDaysToProtest();
      }
      hasLoadedDaysToProtest.current = true;
    }
  }, [billet, loadDaysToProtest]);

  const billetSchema = Yup.object().shape({
    drawee: Yup.string().required('Cliente é obrigatório'),
    price: Yup.string().required('Preço é obrigatório'),
    emition_date: Yup.date().required('Data de emissão é obrigatória'),
    financial_data: Yup.string().required('Dados financeiros é obrigatório'),
    title_number: Yup.string().required('Número do título é obrigatório'),
    expiration_date: Yup.date().required('Data de vencimento é obrigatória'),
    interest: Yup.number().required('Juros é obrigatório'),
    fine: Yup.number().required('Multa é obrigatória'),
    days_to_fine: Yup.number().required('Dias para multar é obrigatório'),
    days_to_protest: Yup.string().required('Dias para protestar é obrigatório'),
    message: Yup.string().nullable(),
    obs: Yup.string().nullable(),
    billet_url: Yup.string().nullable(),
  });

  const handleSave = useCallback(async () => {
    try {
      const emition_date = billet?.emition_date
        ? new Date(billet.emition_date).toISOString()
        : null;
      const expiration_date = billet?.expiration_date
        ? new Date(billet.expiration_date).toISOString()
        : null;

      const billet_finance = finance.map(item => ({
        ...item,
        price: item.price?.replace(/[^\d,]/g, '').replace(',', '.'),
        temp_id: null,
      }));

      const formattedBillet = {
        ...billet,
        emition_date,
        expiration_date,
        billet_finance,
      };
      await billetSchema.validate(formattedBillet, { abortEarly: false });

      await api.put(`/billet/${match.params.id}`, formattedBillet);

      toast.success('Boleto alterado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/billet');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
        formRef.current.setErrors(validationErrors);
      } else {
        toast.error('Falha ao salvar boleto.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [billet, billetSchema, finance, history, match.params.id]);

  const confirmSaveForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={handleSave}
            onClose={onClose}
            message="Todos os dados serão salvos com esta ação."
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleSave]);

  const handleCancel = useCallback(() => {
    history.push('/billet');
  }, [history]);

  const confirmCalcelForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleCancel} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleCancel]);

  const handleChange = (e, name) => {
    const { value } = e.target;

    setBillet(prevBillet => ({
      ...prevBillet,
      [name]: value,
    }));
  };

  const handleChangeFinance = (e, index, name) => {
    const { value } = e.target;

    setFinance(prevFinance =>
      prevFinance.map(item => {
        if (item.temp_id !== index) return item;

        const updatedItem = { ...item };

        if (name === 'price') {
          const formattedValue = formatValueOnChange(value);
          updatedItem[name] = formattedValue;
        } else {
          updatedItem[name] = value;
        }

        return updatedItem;
      })
    );
  };

  const handleChangeSelect = (selectedOption, name) => {
    setBillet(prevBillet => {
      const newBillet = {
        ...prevBillet,
        [name]: selectedOption.value,
      };

      if (name === 'financial') {
        if (newBillet.protest !== 3) {
          loadDaysToProtest(newBillet.financial, newBillet.protest);
        }
      }

      if (name === 'protest' && selectedOption.value === 3) {
        newBillet.days_to_protest = null;
      } else {
        loadDaysToProtest(newBillet.financial, newBillet.protest);
      }
      return newBillet;
    });
  };

  const handleChangeSelectFinance = (selectedOption, index, name) => {
    setFinance(prevFinance =>
      prevFinance.map(item => {
        if (item.temp_id !== index) return item;

        const updatedItem = { ...item, [name]: selectedOption.value };
        return updatedItem;
      })
    );
  };

  const expirationTypeOptions = [
    { value: 1, label: 'Contra apresentação' },
    { value: 2, label: 'Data' },
  ];

  const interestTypeOptions = [
    { value: 1, label: '(R$) Valor por dia' },
    { value: 2, label: '(%) ao mês' },
  ];

  const fineTypeOptions = [
    { value: 0, label: 'Não' },
    { value: 1, label: '(R$) Valor fixo' },
    { value: 2, label: '(%) ao mês' },
  ];

  const protestOptions = [
    { value: 1, label: 'Protestar em (x) dias corridos' },
    { value: 2, label: 'Protestar em (x) dias úteis' },
    { value: 3, label: 'Não' },
  ];

  const handleAddNewFinance = useCallback(() => {
    const newFinance = {
      temp_id: uuidv4(),
      eidref: billet.id,
      company_id: billet.company_id,
      mainid: user.old_id,
      date: new Date(),
      account: '',
      financial: billet.financial,
      name: '',
      price: '',
      status: billet.status,
    };

    setFinance(prevFinance => [newFinance, ...(prevFinance || [])]);
  }, [billet, user]);

  const handleRemoveFinance = useCallback(async index => {
    setFinance(prevFinance =>
      prevFinance
        .map(item =>
          item.temp_id === index ? { ...item, status: true } : item
        )
        .filter(item => item.temp_id !== index)
    );
  }, []);

  return (
    <Container>
      <Header>
        <div>
          <FaBarcode />
          <h1>Cobrança - Boletos</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={confirmSaveForm}>
          <FaSave size={15} color="#44546a" />
          <span>Salvar</span>
        </button>
        <button type="button" onClick={confirmCalcelForm}>
          <FaTimesCircle />
          <span>Cancelar</span>
        </button>
      </Controls>
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {billet && (
            <BilletForm ref={formRef} onSubmit={handleSave}>
              <h4>Boleto</h4>
              <section>
                <Select
                  label="Cliente (Sacado)"
                  name="drawee"
                  className="drawee"
                  options={clientsOptions}
                  placeholder="Selecione um cliente"
                  value={clientsOptions.find(
                    option => option.value === billet?.drawee
                  )}
                  onChange={option => handleChangeSelect(option, 'drawee')}
                />
                <Input
                  name="price"
                  className="price"
                  label="Preço"
                  value={billet.price}
                  readOnly
                />
                <DatePicker
                  name="emition_date"
                  label="Emissão"
                  selected={
                    billet?.emition_date ? new Date(billet?.emition_date) : null
                  }
                  onChange={date =>
                    setBillet(prevBillet => ({
                      ...prevBillet,
                      emition_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione uma data"
                />
                <Select
                  label="Dados financeiros"
                  name="financial"
                  className="financial_data"
                  options={financialOptions}
                  placeholder="Selecione um dado financeiro"
                  value={financialOptions.find(
                    option => option.value === billet?.financial
                  )}
                  onChange={option => handleChangeSelect(option, 'financial')}
                />
              </section>
              <section>
                <Input
                  name="title_number"
                  className="title_number"
                  label="Número do título"
                  defaultValue={billet.title_number}
                  onChange={e => handleChange(e, 'title_number')}
                />
                <Select
                  label="Tipo de vencimento"
                  name="expiration_type"
                  className="expiration_type"
                  options={expirationTypeOptions}
                  placeholder="Selecione um tipo de vencimento"
                  defaultValue={expirationTypeOptions.find(
                    option => option.value === billet?.expiration_type
                  )}
                  onChange={option =>
                    handleChangeSelect(option, 'expiration_type')
                  }
                />
                <DatePicker
                  name="expiration_date"
                  label="Vencimento"
                  selected={
                    billet?.expiration_date
                      ? new Date(billet?.expiration_date)
                      : null
                  }
                  onChange={date =>
                    setBillet(prevBillet => ({
                      ...prevBillet,
                      expiration_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione uma data"
                />
              </section>
              <section>
                <Input
                  name="interest"
                  className="interest"
                  label="Juros"
                  defaultValue={billet.interest}
                  onChange={e => handleChange(e, 'interest')}
                />
                <Select
                  label="Juros (Tipo)"
                  name="interest_type"
                  className="interest_type"
                  options={interestTypeOptions}
                  placeholder="Selecione a forma de juros"
                  defaultValue={interestTypeOptions.find(
                    option => option.value === billet?.interest_type
                  )}
                  onChange={option =>
                    handleChangeSelect(option, 'interest_type')
                  }
                />
                <Input
                  name="fine"
                  className="fine"
                  label="Multa"
                  defaultValue={billet.fine}
                  onChange={e => handleChange(e, 'fine')}
                />
                <Select
                  label="Multa (Tipo)"
                  name="fine_type"
                  className="fine_type"
                  options={fineTypeOptions}
                  placeholder="Selecione a forma de multa"
                  defaultValue={fineTypeOptions.find(
                    option => option.value === billet?.fine_type
                  )}
                  onChange={option => handleChangeSelect(option, 'fine_type')}
                />
                <Input
                  name="days_to_fine"
                  className="days_to_fine"
                  label="Dias para multar"
                  defaultValue={billet.days_to_fine}
                  onChange={e => handleChange(e, 'days_to_fine')}
                />
                <Select
                  label="Protestar"
                  name="protest"
                  className="protest"
                  options={protestOptions}
                  placeholder="Selecione a forma de protesto"
                  defaultValue={protestOptions.find(
                    option => option.value === billet?.protest
                  )}
                  onChange={option => handleChangeSelect(option, 'protest')}
                />
                <Select
                  label="Dias para protestar"
                  name="days_to_protest"
                  className="days_to_protest"
                  options={daysToProtestOptions}
                  placeholder="Selecione a quantidade de dias"
                  value={
                    daysToProtestOptions.find(
                      option => option.value === billet?.days_to_protest
                    ) || null
                  }
                  onChange={option =>
                    handleChangeSelect(option, 'days_to_protest')
                  }
                  isDisabled={billet?.protest === 3}
                />
              </section>
              <section>
                <Input
                  name="message"
                  className="message"
                  label="Mensagem"
                  defaultValue={billet?.message}
                  onChange={e => handleChange(e, 'message')}
                />
              </section>
              <section>
                <Input
                  name="obs"
                  className="obs"
                  label="Observação"
                  defaultValue={billet?.obs}
                  onChange={e => handleChange(e, 'obs')}
                />
              </section>
              <section>
                <Input
                  name="billet_url"
                  className="billet_url"
                  label="Link"
                  defaultValue={billet?.billet_url}
                  onChange={e => handleChange(e, 'billet_url')}
                />
              </section>

              <Finances>
                <header>
                  <h4>Eventos</h4>
                  <button type="button" onClick={handleAddNewFinance}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {finance?.length > 0 &&
                  finance.map(item => (
                    <Finance key={item.temp_id}>
                      <button
                        type="button"
                        onClick={() => handleRemoveFinance(item.temp_id)}
                      >
                        <FaMinus size={10} />
                      </button>

                      <section>
                        <Select
                          label="Evento"
                          name="account"
                          className="account"
                          options={eventsOptions}
                          placeholder="Selecione um evento"
                          value={eventsOptions.find(
                            option => option.value === item.account
                          )}
                          onChange={option =>
                            handleChangeSelectFinance(
                              option,
                              item.temp_id,
                              'account'
                            )
                          }
                        />
                        <InputMask
                          name="name"
                          className="name"
                          label="Competência"
                          mask="99/9999"
                          value={item.name}
                          onChange={e =>
                            handleChangeFinance(e, item.temp_id, 'name')
                          }
                        />
                        <Input
                          name="price"
                          className="price"
                          label="Valor"
                          value={item.price}
                          onChange={e =>
                            handleChangeFinance(e, item.temp_id, 'price')
                          }
                        />
                      </section>
                    </Finance>
                  ))}
              </Finances>
            </BilletForm>
          )}
        </Content>
      )}
    </Container>
  );
};

export default Edit;
